import React, { useEffect } from "react";
import RadioGroup from "../../components/RadioGroup";
import { Disclosure } from "@headlessui/react";
import Input from "../../components/Input";
import moment from "moment";
import Timer from "../../components/Timer";
import { motion } from "framer-motion";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const TOUCH_THRESHOLD = 150;

const COLORS = {
  1: "text-blue-500",
  2: "text-green-500",
  3: "text-red-500",
  4: "text-blue-700",
  5: "text-red-700",
  6: "text-teal-600",
  7: "text-white",
  8: "text-gray-500",
};

export default function Minesweeper() {
  const [sizeStr, setSizeStr] = React.useState("Beginner");
  const [customInfo, setCustomInfo] = React.useState({
    height: 16,
    width: 30,
    mines: 99,
  });

  const size = { ...customInfo };

  if (sizeStr === "Beginner") {
    size.height = 9;
    size.width = 9;
    size.mines = 10;
  }
  if (sizeStr === "Intermediate") {
    size.height = 16;
    size.width = 16;
    size.mines = 40;
  }
  if (sizeStr === "Expert") {
    size.height = 16;
    size.width = 30;
    size.mines = 99;
  }

  return (
    <div>
      Play Against:
      <div className="flex gap-6">
        <RadioGroup
          options={["Beginner", "Intermediate", "Expert", "Custom"]}
          value={sizeStr}
          onChange={setSizeStr}
        />
        <div className="text-gray-500">
          (9x9 with 10 mines)
          <br />
          (16x16 with 40 mines)
          <br />
          (16x30 with 99 mines)
          <br />
          <div className="flex flex-wrap gap-x-3">
            <div>
              Height:
              <Input
                disabled={sizeStr !== "Custom"}
                className="w-[5em]"
                type="number"
                value={customInfo.height}
                otherProps={{ min: 1 }}
                onChange={(e) => {
                  var val = +e.target.value;
                  val = Math.max(1, val);
                  val = Math.min(val, 30);

                  // If too many mines, reduce mines
                  var maxMines = val * customInfo.width - 1;

                  setCustomInfo({
                    ...customInfo,
                    height: val,
                    ...(maxMines < customInfo.mines && { mines: maxMines }),
                  });
                }}
              />
            </div>
            <div>
              Width:
              <Input
                disabled={sizeStr !== "Custom"}
                className="w-[5em]"
                type="number"
                value={customInfo.width}
                otherProps={{ min: 1 }}
                onChange={(e) => {
                  var val = +e.target.value;
                  val = Math.max(1, val);
                  val = Math.min(val, 50);

                  // If too many mines, reduce mines
                  var maxMines = val * customInfo.height - 1;

                  setCustomInfo({
                    ...customInfo,
                    width: val,
                    ...(maxMines < customInfo.mines && { mines: maxMines }),
                  });
                }}
              />
            </div>
            <div>
              Mines:
              <Input
                disabled={sizeStr !== "Custom"}
                className="w-[5em]"
                type="number"
                value={customInfo.mines}
                otherProps={{ min: 1 }}
                onChange={(e) => {
                  var val = +e.target.value;
                  val = Math.max(1, val);
                  val = Math.min(val, customInfo.height * customInfo.width - 1);
                  setCustomInfo({ ...customInfo, mines: val });
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <br />
      <div className="mb-4">
        <Disclosure>
          <Disclosure.Button className="cursor-pointer hover:underline">
            [Toggle Rules]
          </Disclosure.Button>
          <Disclosure.Panel className="text-gray-500">
            <article>
              Minesweeper is a single player game where the player tries to
              uncover all the squares without blowing up a mine. If a square is
              adjacent to a mine, it will show the number of mines that it is
              adjacent to. If a square that is not adjacent to any mines is
              revealed, it will uncover all adjacent squares. If a mine is
              revealed, the game is over.
            </article>
          </Disclosure.Panel>
        </Disclosure>
        <br />
        <Disclosure>
          <Disclosure.Button className="cursor-pointer hover:underline">
            [Toggle Controls]
          </Disclosure.Button>
          <Disclosure.Panel className="text-gray-500">
            <article>
              LMB/Touch - Reveal Square / Chord Square
              <br />
              RMB/Hold Touch - Flag Square
              <br />
              <br />
              Chording is when you reveal a square that is already revealed and
              has the correct number of flags around it. This will reveal all
              unflagged adjacent squares.
              <br />
              <br />
              For mobile, tap to reveal a square and hold to flag a square. When
              flagging, a red circle will appear around the square to notify you
              that it is flagged. If you have any issues with the controls,
              please let me know.
            </article>
          </Disclosure.Panel>
        </Disclosure>
      </div>
      <Game size={size} />
    </div>
  );
}

function Game({ size }) {
  const [board, setBoard] = React.useState(
    Array(size.height)
      .fill(null)
      .map(() => Array(size.width).fill(null))
  );
  const [mines, setMines] = React.useState(null);
  const [gameStatus, setGameStatus] = React.useState("Playing"); // "Playing", "Won", "Lost"
  const [finalPos, setFinalPos] = React.useState(null); // [i, j]
  const [startTime, setStartTime] = React.useState(null);

  // Use callback for reset
  const reset = React.useCallback(() => {
    setBoard(
      Array(size.height)
        .fill(null)
        .map(() => Array(size.width).fill(null))
    );
    setGameStatus("Playing");
    setMines(null);
    setFinalPos(null);
    setStartTime(null);
  }, [size]);

  // Change board size and reset when size changes
  useEffect(() => {
    reset();
  }, [size, reset]);

  function fillMines(avoid_i = null, avoid_j = null) {
    var newMines = Array(size.height)
      .fill(null)
      .map(() => Array(size.width).fill(false));

    var minesPlaced = 0;
    while (minesPlaced < size.mines) {
      var i = Math.floor(Math.random() * size.height);
      var j = Math.floor(Math.random() * size.width);

      // If first click, make sure it is not a mine
      if (avoid_i === i && avoid_j === j) {
        continue;
      }

      if (!newMines[i][j]) {
        newMines[i][j] = true;
        minesPlaced++;
      }
    }

    setMines(newMines);
    return newMines;
  }

  function handleClick(i, j) {
    // If game over, do nothing
    if (gameStatus !== "Playing") {
      return;
    }

    // If flagged, do nothing
    if (board[i][j] === -1) {
      return;
    }

    // If first click, fill mines and make sure first click is not a mine
    var currMines = mines;
    if (!currMines) {
      currMines = fillMines(i, j);
      setStartTime(moment());
    }

    // Reveal square or chord

    // If number, chord
    var newBoard = board.map((row) => row.slice());
    if (board[i][j] != null) {
      // Count flags
      var numFlags = 0;
      for (let di = -1; di <= 1; di++) {
        for (let dj = -1; dj <= 1; dj++) {
          if (i + di < 0 || i + di >= size.height) {
            continue;
          }
          if (j + dj < 0 || j + dj >= size.width) {
            continue;
          }
          if (board[i + di][j + dj] === -1) {
            numFlags++;
          }
        }
      }

      // If flags match number, chord
      if (numFlags === newBoard[i][j]) {
        for (let di = -1; di <= 1; di++) {
          for (let dj = -1; dj <= 1; dj++) {
            if (i + di < 0 || i + di >= size.height) {
              continue;
            }
            if (j + dj < 0 || j + dj >= size.width) {
              continue;
            }
            if (newBoard[i + di][j + dj] === -1) {
              continue;
            }
            newBoard = reveal(i + di, j + dj, newBoard, currMines);
          }
        }
      }
    }

    // If empty, reveal
    else {
      newBoard = reveal(i, j, newBoard, currMines);
    }

    // Check if won
    var won = true;
    for (let i = 0; i < size.height; i++) {
      for (let j = 0; j < size.width; j++) {
        if (newBoard[i][j] === null && !currMines[i][j]) {
          won = false;
        }
      }
    }
    if (won) {
      // Set all mines to flagged
      for (let i = 0; i < size.height; i++) {
        for (let j = 0; j < size.width; j++) {
          if (currMines[i][j]) {
            newBoard[i][j] = -1;
          }
        }
      }
      setGameStatus("Won");
    }

    // Update
    setBoard(newBoard);
  }

  function reveal(i, j, board, mines) {
    // If already revealed, do nothing
    if (board[i][j] != null) {
      return board;
    }

    // If mine, lose
    if (mines[i][j]) {
      setGameStatus("Lost");
      setFinalPos([i, j]);
      return board;
    }

    // If adjacent to mine, reveal number
    var numMines = 0;
    for (let di = -1; di <= 1; di++) {
      for (let dj = -1; dj <= 1; dj++) {
        if (i + di < 0 || i + di >= size.height) {
          continue;
        }
        if (j + dj < 0 || j + dj >= size.width) {
          continue;
        }
        if (mines[i + di][j + dj]) {
          numMines++;
        }
      }
    }
    if (numMines > 0) {
      board[i][j] = numMines;
      return board;
    }

    // If not adjacent to mine, reveal all adjacent squares
    board[i][j] = 0;
    for (let di = -1; di <= 1; di++) {
      for (let dj = -1; dj <= 1; dj++) {
        if (i + di < 0 || i + di >= size.height) {
          continue;
        }
        if (j + dj < 0 || j + dj >= size.width) {
          continue;
        }
        board = reveal(i + di, j + dj, board, mines);
      }
    }

    return board;
  }

  function handleFlag(i, j) {
    // If no mines, do nothing
    if (!mines || gameStatus !== "Playing") {
      return;
    }

    // If empty, flag (-1). If flag, unflag (null). If number, do nothing
    if (!board[i][j]) {
      setBoard((board) => {
        const newBoard = board.slice();
        newBoard[i][j] = -1;
        return newBoard;
      });
    } else if (board[i][j] === -1) {
      setBoard((board) => {
        const newBoard = board.slice();
        newBoard[i][j] = null;
        return newBoard;
      });
    }
  }
  // Box characters
  // ┌┬─┬┐
  // ├┼─┼┤
  // └┴─┴┘

  var rowSep = (type) => {

    var size = 3;
    var horiz = "─".repeat(size);

    var top = "┌" + Array(board[0].length).fill(horiz).join("┬") + "┐";
    var middle = "├" + Array(board[0].length).fill(horiz).join("┼") + "┤";
    var bottom = "└" + Array(board[0].length).fill(horiz).join("┴") + "┘";

    let rowSep = middle;
    if (type == "top") {
      rowSep = top;
    }
    if (type == "bottom") {
      rowSep = bottom;
    }


    return (
      <span
        className={gameStatus !== "Playing" ? "text-gray-700" : "text-gray-300"}
      >
        {rowSep}
      </span>
    );
  }

  return (
    <pre className="select-none">
      {rowSep("top")}
      <br />
      {board.map((row, i) => (
        <React.Fragment key={i}>
          <span
            className={
              gameStatus !== "Playing" ? "text-gray-500" : "text-gray-300"
            }
          >
            |
          </span>
          {row.map((square, j) => (
            <React.Fragment key={j}>
              <Square
                value={square}
                onClick={() => handleClick(i, j)}
                onRightClick={() => handleFlag(i, j)}
                i={i}
                j={j}
                mines={mines}
                lost={gameStatus === "Lost"}
                finalPos={finalPos && finalPos[0] === i && finalPos[1] === j}
                notFirstClick={!!mines}
              />
              <span
                className={
                  gameStatus !== "Playing" ? "text-gray-500" : "text-gray-300"
                }
              >
                |
              </span>
            </React.Fragment>
          ))}
          <br />
          {rowSep(i === board.length - 1 ? "bottom" : "middle")}
          <br />
        </React.Fragment>
      ))}

      <br />
      <span className="select-text">
        {/*  Mines Left */}
        {startTime && (
          <>
            Mines Left:{" "}
            <span className="text-gray-300">
              {mines &&
                size.mines -
                board
                  .flat()
                  .filter((square) => square === -1)
                  .length.toString()}
            </span>
            <br />
          </>
        )}

        {/* Timer */}
        {startTime && (
          <>
            Time:{" "}
            <Timer
              startTime={startTime}
              running={gameStatus === "Playing"}
              className={gameStatus === "Playing" ? "text-gray-700" : ""}
            />
          </>
        )}

        <br />

        {gameStatus !== "Playing" && (
          <>
            {gameStatus === "Won" ? (
              <span className="text-green-500">You Won :)</span>
            ) : (
              <span className="text-red-500">You Lost :(</span>
            )}{" "}
            <span
              className="text-green-300 cursor-pointer hover:underline"
              onClick={reset}
            >
              [Play Again]
            </span>
          </>
        )}
      </span>
    </pre>
  );
}

function Square({
  value,
  onClick,
  onRightClick,
  i,
  j,
  mines,
  lost = false,
  finalPos = false,
}) {
  const [touchStart, setTouchStart] = React.useState(null);
  const [touching, setTouching] = React.useState(false);
  const [showFlash, setShowFlash] = React.useState(false);

  // Use effect to check if touch is long enough
  // Start flash if it is
  useEffect(() => {
    if (touchStart) {
      const timeout = setTimeout(() => {
        if (!touching) {
          return;
        }
        setShowFlash(true);
        setTouchStart(null);
        onRightClick();
      }, TOUCH_THRESHOLD);
      return () => clearTimeout(timeout);
    }
  }, [touchStart, onRightClick, touching]);

  // If null, display filled in square
  var display = (
    <span
      className={
        finalPos ? "bg-red-500 text-gray-700" : "bg-gray-700 text-red-500"
      }
    >
      {" "}
      {mines && mines[i][j] && lost ? "X" : " "}{" "}
    </span>
  );

  // Flagged
  if (value === -1) {
    var badFlag = mines && !mines[i][j];
    display = (
      <span
        className={classNames(
          "bg-gray-700 font-bold text-red-500",
          badFlag && lost ? "line-through" : ""
        )}
      >
        {" F "}
      </span>
    );
  }

  // Number
  else if (value != null) {
    display = <span className={COLORS[value]}> {value || " "} </span>;
  }

  return (
    <span
      className="w-6 h-6 cursor-pointer relative"
      // Mobile
      onTouchStart={(e) => {
        setTouchStart(moment());
        setTouching(true);
      }}
      onTouchEnd={(e) => {
        if (touchStart && moment().diff(touchStart) < TOUCH_THRESHOLD) {
          onClick();
        }
        setTouching(false);
        setTouchStart(null);
      }}
      onClick={(e) => {
        // If touch, do nothing
        if (touchStart) {
          return;
        }
        e.preventDefault();
        onClick();
      }}
      onContextMenu={(e) => {
        e.preventDefault();
        onRightClick();
      }}
    >
      {showFlash && (
        <motion.span
          initial={{
            opacity: 0.8,
            scale: 0.5,
          }}
          animate={{
            opacity: 0,
            scale: 2,
          }}
          transition={{
            duration: 0.5,
          }}
          onAnimationComplete={() => setShowFlash(false)}
          className="z-10 absolute w-[200px] h-[200px] -top-[75px] -left-[75px] bg-red-500 rounded-full origin-center pointer-events-none"
        />
      )}
      {display}
    </span>
  );
}
