import React from "react";
import { Link } from "react-router-dom";

export default function Donate() {
  return (
    <article>
      <Link to="/" className="text-green-500">
        [txt]ure
      </Link>{" "}
      is run by just me. Feel free to take a look at{" "}
      <a className="text-blue-500" href="https://eef.app" target="_blank">
        [my other projects]
      </a>{" "}
      or{" "}
      <a
        href="https://ethantchristensen.com"
        className="text-blue-500"
        target="_blank"
      >
        [my portfolio]
      </a>
      . If you like what I'm doing, please consider{" "}
      <a
        href="https://www.buymeacoffee.com/eefapp"
        className="text-blue-500"
        target="_blank"
      >
        [buying me a coffee]
      </a>
      . Thank you!
    </article>
  );
}
