import React from "react";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function RadioGroup({
  options,
  value,
  onChange = () => {},
  disabled = false,
}) {
  function handleClick(option) {
    if (!disabled) {
      onChange(option);
    }
  }

  return (
    <ul className={classNames("whitespace-nowrap", disabled ? "text-gray-500" : undefined)}>
      {options.map((option) => (
        <li key={option} onClick={() => handleClick(option)}>
          {value === option ? (
            "[x]"
          ) : (
            <span className={!disabled ? "cursor-pointer" : undefined}>
              [ ]
            </span>
          )}{" "}
          {option}
        </li>
      ))}
    </ul>
  );
}
