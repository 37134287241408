import "./App.css";
import Layout from "./pages/Layout";
import Home from "./pages/Home";
import { Routes, Route } from "react-router-dom";

import Gallery from "./pages/Gallery";
import Games from "./pages/Games";
import About from "./pages/About";
import Donate from "./pages/Donate";

import Error404 from "./pages/Error404";
import TicTacToe from "./pages/games/TicTacToe";
import ConnectFour from "./pages/games/ConnectFour";
import Minesweeper from "./pages/games/Minesweeper";
import Solitaire from "./pages/games/Solitaire";
import Hangman from "./pages/games/Hangman";

function App() {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<Home />} />
        <Route path="gallery" element={<Gallery />} />
        <Route path="games">
          <Route index  element={<Games />} />
          <Route path="connect-four" element={<ConnectFour />} />
          <Route path="minesweeper" element={<Minesweeper />} />
          <Route path="tictactoe" element={<TicTacToe />} />
          <Route path="solitaire" element={<Solitaire />} />
          <Route path="hangman" element={<Hangman />} />
        </Route>
        <Route path="about" element={<About />} />
        <Route path="donate" element={<Donate />} />
        <Route path="*" element={<Error404 />} />
      </Route>
    </Routes>
  );
}

export default App;
