import React from "react";
import RadioGroup from "../../components/RadioGroup";
import { Disclosure } from "@headlessui/react";
import { set } from "mongoose";


// Box characters
// ┌┬─┬┐
// ├┼─┼┤
// └┴─┴┘

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function ConnectFour() {
  const [opponent, setOpponent] = React.useState("Human");
  const [inPlay, setInPlay] = React.useState(false);

  return (
    <div>
      Play Against:
      <RadioGroup
        options={["Human", "Easy Computer", "Hard Computer"]}
        value={opponent}
        onChange={setOpponent}
        disabled={inPlay}
      />
      {/* // TODO: implement impossible connect four */}
      <p className="text-gray-500">[ ] Impossible Computer (Coming Soon)</p>
      <br />
      <div className="mb-4">
        <Disclosure>
          <Disclosure.Button className="cursor-pointer hover:underline">
            [Toggle Rules]
          </Disclosure.Button>
          <Disclosure.Panel className="text-gray-500">
            <article>
              Connect Four is a two player game where the players take turns
              dropping discs into a selected column. The discs fall to the
              lowest available space in the column. The first to connect four of
              their discs in a row, either vertically, horizontally, or
              diagonally, wins.
            </article>
          </Disclosure.Panel>
        </Disclosure>
      </div>
      <Game opponent={opponent} inPlayChanged={(p) => setInPlay(p)} />
    </div>
  );
}

function Game({ opponent, inPlayChanged }) {
  const [board, setBoard] = React.useState(
    new Array(6).fill(null).map((a) => new Array(7).fill(null))
  ); // 6x7 array (6 rows, 7 columns)

  const [turn, setTurn] = React.useState("blue"); // red or blue
  const [inPlay, setInPlay] = React.useState(false);
  const [winner, setWinner] = React.useState(null);

  // If computer's turn, make a move
  React.useEffect(() => {
    if (
      ["Easy Computer", "Hard Computer"].includes(opponent) &&
      turn === "red" &&
      inPlay
    ) {
      makeComputerMove();
    }
  }, [turn]);

  function move(col) {
    // If column is full, do nothing
    if (isColFull(col)) {
      return;
    }

    // Find the lowest available row in the column
    var row = 0;
    while (row < board.length && board[row][col] == null) {
      row++;
    }
    row--;

    // If first move, start game
    if (!inPlay) {
      setInPlay(true);
      inPlayChanged(true);
    }

    // Make move
    const newBoard = [...board];
    newBoard[row][col] = turn;
    setBoard(newBoard);

    // Switch turns
    const newTurn = turn === "blue" ? "red" : "blue";
    setTurn(newTurn);

    // If game over, stop game
    if (!winner) {
      var _winner = calculateWinner(newBoard, setBoard);
      console.log(_winner);
      if (_winner) {
        setWinner(_winner);
        setInPlay(false);
        inPlayChanged(false);
        return;
      }
    }
  }

  function calculateWinner(board, setBoard = () => { }) {
    // Check verticals
    for (let j = 0; j < board[0].length; j++) {
      for (let i = 0; i < board.length - 3; i++) {
        if (
          board[i][j] &&
          board[i][j] == board[i + 1][j] &&
          board[i + 1][j] == board[i + 2][j] &&
          board[i + 2][j] == board[i + 3][j]
        ) {
          // Need to copy more than just the row
          var newBoard = board.map((row) => row.map((col) => col));
          newBoard[i][j] = "><";
          newBoard[i + 1][j] = "><";
          newBoard[i + 2][j] = "><";
          newBoard[i + 3][j] = "><";
          setBoard(newBoard);
          return board[i][j];
        }
      }
    }

    // Check horizontals
    for (let i = 0; i < board.length; i++) {
      for (let j = 0; j < board[0].length - 3; j++) {
        if (
          board[i][j] &&
          board[i][j] == board[i][j + 1] &&
          board[i][j + 1] == board[i][j + 2] &&
          board[i][j + 2] == board[i][j + 3]
        ) {
          var newBoard = board.map((row) => row.map((col) => col));
          newBoard[i][j] = "><";
          newBoard[i][j + 1] = "><";
          newBoard[i][j + 2] = "><";
          newBoard[i][j + 3] = "><";
          setBoard(newBoard);
          return board[i][j];
        }
      }
    }

    // Check diagonals
    for (let i = 0; i < board.length - 3; i++) {
      for (let j = 0; j < board[0].length - 3; j++) {
        if (
          board[i][j] &&
          board[i][j] == board[i + 1][j + 1] &&
          board[i + 1][j + 1] == board[i + 2][j + 2] &&
          board[i + 2][j + 2] == board[i + 3][j + 3]
        ) {
          var newBoard = board.map((row) => row.map((col) => col));
          newBoard[i][j] = "><";
          newBoard[i + 1][j + 1] = "><";
          newBoard[i + 2][j + 2] = "><";
          newBoard[i + 3][j + 3] = "><";
          setBoard(newBoard);
          return board[i][j];
        }

        if (
          board[i + 3][j] &&
          board[i + 3][j] == board[i + 2][j + 1] &&
          board[i + 2][j + 1] == board[i + 1][j + 2] &&
          board[i + 1][j + 2] == board[i][j + 3]
        ) {
          var newBoard = board.map((row) => row.map((col) => col));
          newBoard[i + 3][j] = "><";
          newBoard[i + 2][j + 1] = "><";
          newBoard[i + 1][j + 2] = "><";
          newBoard[i][j + 3] = "><";
          setBoard(newBoard);
          return board[i + 3][j];
        }
      }
    }

    // Check for tie
    var tie = true;
    for (let i = 0; i < board.length; i++) {
      if (board[i].includes(null)) {
        tie = false;
      }
    }
    if (tie) {
      return "tie";
    }

    return null;
  }

  function makeComputerMove() {
    // Get available columns
    var availableCols = [];
    for (let j = 0; j < board[0].length; j++) {
      if (!isColFull(j)) {
        availableCols.push(j);
      }
    }

    if (opponent === "Easy Computer") {
      // Pick a random column
      var col = availableCols[Math.floor(Math.random() * availableCols.length)];
      move(col);
    } else if (opponent === "Hard Computer") {
      // Check if any column will win
      for (let j = 0; j < availableCols.length; j++) {
        var newBoard = board.map((row) => row.map((col) => col));
        // Find the lowest available row in the column
        var row = 0;
        while (row < board.length && board[row][availableCols[j]] == null) {
          row++;
        }
        row--;
        newBoard[row][availableCols[j]] = turn;
        if (calculateWinner(newBoard) === turn) {
          move(availableCols[j]);
          return;
        }
      }

      // Check if any column will block a win
      for (let j = 0; j < availableCols.length; j++) {
        var newBoard = board.map((row) => row.map((col) => col));
        // Find the lowest available row in the column
        var row = 0;
        while (row < board.length && board[row][availableCols[j]] == null) {
          row++;
        }
        row--;
        newBoard[row][availableCols[j]] = turn === "blue" ? "red" : "blue";
        if (calculateWinner(newBoard) === (turn === "blue" ? "red" : "blue")) {
          move(availableCols[j]);
          return;
        }
      }

      // Otherwise, pick a random column
      var col =
        availableCols[Math.floor(Math.random() * availableCols.length)];
      move(col);
    }
  }

  function reset() {
    setBoard(new Array(6).fill(null).map((a) => new Array(7).fill(null)));
    setTurn("blue");
    setWinner(null);
    setInPlay(false);
    inPlayChanged(false);
  }

  function isColFull(col) {
    return board[0][col] != null;
  }

  return (
    <pre>
      {/* Board */}
      {board[0].map((col, j) => (
        <>
          <span
            key={j}
            onClick={() => move(j)}
            className={
              isColFull(j) || winner
                ? "text-gray-500"
                : "text-green-300 cursor-pointer"
            }
          >
            {" "}
            <span className={isColFull(j) ? "" : "hover:underline"}>vv</span>
          </span>
        </>
      ))}
      <br />
      ┌──┬──┬──┬──┬──┬──┬──┐
      <br />
      {board.map((row, i) => (
        <>
          {row.map((col, j) => {
            var output = <>|</>;

            if (col) {
              output = (
                <>
                  {output}
                  <span
                    key={i + "-" + j}
                    className={
                      (col == "><" && winner == "blue") || col == "blue"
                        ? "text-blue-500"
                        : "text-red-500"
                    }
                  >
                    {col != "--" && ""}
                    {col == "><" ? "><" : "<>"}
                    {col != "--" && ""}
                  </span>
                </>
              );
            } else {
              output = (
                <>
                  {output}
                  {"  "}
                </>
              );
            }

            return <React.Fragment key={i + "-" + j}>{output}</React.Fragment>;
          })}
          |
          <br />
          {
            row === board[board.length - 1] ? (
              <>
              └──┴──┴──┴──┴──┴──┴──┘
              </>
            ) : (
              <>
                ├──┼──┼──┼──┼──┼──┼──┤
              </>
            )
          }
          <br />
        </>
      ))}
      {/* Turn */}
      <p className="text-gray-500">
        Turn:{" "}
        <span className={turn == "blue" ? "text-blue-500" : "text-red-500"}>
          {turn}
        </span>
      </p>
      <br />
      {/* Winner */}
      {winner && (
        <div>
          {winner === "tie" ? (
            "Tie! "
          ) : (
            <p>
              <span
                className={classNames(
                  "capitalize",
                  winner == "blue" ? "text-blue-500" : "text-red-500"
                )}
              >
                {winner}
              </span>{" "}
              wins!{" "}
            </p>
          )}
          <span
            className="text-green-300 cursor-pointer hover:underline"
            onClick={reset}
          >
            [Play Again]
          </span>
        </div>
      )}
    </pre>
  );
}
