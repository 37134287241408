import React from "react";
import { Link } from "react-router-dom";

export default function Games() {
  // Current Games
  var games = [
    {
      name: "Tic Tac Toe",
      path: "/games/tictactoe",
    },
    {
      name: "Connect Four",
      path: "/games/connect-four",
    },
    {
      name: "Minesweeper",
      path: "/games/minesweeper",
    },
    {
      name: "Solitaire",
      path: "/games/solitaire",
    },
    {
      name: "Hangman",
      path: "/games/hangman",
    }
  ];
  // Sort by name
  games.sort((a, b) => (a.name > b.name ? 1 : -1));

  const upcoming = [
    "Checkers",
    "Chess",
    "Battleship", // Computer only?
    "Tetris",
    "Sudoku",
    "Word Search", // Search and/or wordle?
    // "Crossword", // Too hard?
    "Snake",
    "Pong",
    "Breakout",

  ];
  // Sort by name
  upcoming.sort((a, b) => (a > b ? 1 : -1));

  return (
    <article>
      {/* Current Games */}
      <ul className="flex flex-wrap gap-x-2">
        <li>Play a Game:</li>
        {games.map((game, i) => (
          <li key={i} className="text-green-500"><Link to={game.path}>[{game.name}]</Link></li>
        ))}
      </ul>
      <br />
      {/* Upcoming */}
      <ul className="flex flex-wrap gap-x-2 text-gray-500">
        <li>Coming Soon:</li>
        {upcoming.map((game, i) => (
          <li key={i}>[{game}]</li>
        ))}
      </ul>
    </article>
  );
}
