import React from "react";
import RadioGroup from "../../components/RadioGroup";
import { Disclosure } from "@headlessui/react";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function TicTacToe() {
  const [opponent, setOpponent] = React.useState("Human");
  const [inPlay, setInPlay] = React.useState(false);

  return (
    <div>
      Play Against:
      <RadioGroup
        options={["Human", "Easy Computer", "Hard Computer"]}
        value={opponent}
        onChange={setOpponent}
        disabled={inPlay}
      />
      {/* // TODO: implement impossible tic tac toe */}
      <p className="text-gray-500">[ ] Impossible Computer (Coming Soon)</p>
      <br />
      <div className="mb-4">
        <Disclosure>
          <Disclosure.Button className="cursor-pointer hover:underline">
            [Toggle Rules]
          </Disclosure.Button>
          <Disclosure.Panel className="text-gray-500">
            <article>
              Tic tac toe is a two player game where the players take turns
              placing their mark, X or O, on a 3x3 grid. The first to get 3 in a
              row, either vertically, horizontally, or diagonally, wins.
            </article>
          </Disclosure.Panel>
        </Disclosure>
      </div>
      <Game opponent={opponent} inPlayChanged={(p) => setInPlay(p)} />
    </div>
  );
}

function Game({ opponent, inPlayChanged }) {
  const [board, setBoard] = React.useState(Array(9).fill(null));
  const [turn, setTurn] = React.useState("X");
  const [inPlay, setInPlay] = React.useState(false);
  const [winner, setWinner] = React.useState(null);

  // If computer's turn, make a move
  React.useEffect(() => {
    if (
      ["Easy Computer", "Hard Computer"].includes(opponent) &&
      turn === "O" &&
      inPlay
    ) {
      makeComputerMove();
    }
  }, [turn]);

  function move(i) {
    if (board[i] || winner) {
      return;
    }

    // If first move, start game
    if (!inPlay) {
      setInPlay(true);
      inPlayChanged(true);
    }

    const newBoard = [...board];
    newBoard[i] = turn;
    const newTurn = turn === "X" ? "O" : "X";
    setBoard(newBoard);
    setTurn(newTurn);

    // If game over, stop game
    var _winner = calculateWinner(newBoard, setBoard);
    if (_winner) {
      setWinner(_winner);
      setInPlay(false);
      inPlayChanged(false);
      return;
    }
  }

  function calculateWinner(board, setBoard = () => {}) {
    // Check verticals
    for (let i = 0; i < 3; i++) {
      if (board[i] && board[i] === board[i + 3] && board[i] === board[i + 6]) {
        // Set board to have '|' for winning squares
        var newBoard = [...board];
        newBoard[i] = "|";
        newBoard[i + 3] = "|";
        newBoard[i + 6] = "|";
        setBoard(newBoard);
        return board[i];
      }
    }

    // Check horizontals
    for (let i = 0; i < 9; i += 3) {
      if (board[i] && board[i] === board[i + 1] && board[i] === board[i + 2]) {
        // Set board to have '-' for winning squares
        var newBoard = [...board];
        newBoard[i] = "───";
        newBoard[i + 1] = "───";
        newBoard[i + 2] = "───";
        setBoard(newBoard);
        return board[i];
      }
    }

    // Check diagonals
    if (board[0] && board[0] === board[4] && board[0] === board[8]) {
      // Set board to have '\' for winning squares
      var newBoard = [...board];
      newBoard[0] = "\\";
      newBoard[4] = "\\";
      newBoard[8] = "\\";
      setBoard(newBoard);
      return board[0];
    }
    if (board[2] && board[2] === board[4] && board[2] === board[6]) {
      // Set board to have '/' for winning squares
      var newBoard = [...board];
      newBoard[2] = "/";
      newBoard[4] = "/";
      newBoard[6] = "/";
      setBoard(newBoard);
      return board[2];
    }

    // Check for tie
    if (board.every((square) => square)) {
      return "tie";
    }

    return null;
  }

  function reset() {
    setBoard(Array(9).fill(null));
    setTurn("X");
    setInPlay(false);
    inPlayChanged(false);
    setWinner(null);
  }

  function makeComputerMove() {
    // If easy computer, pick a random open square
    if (opponent === "Easy Computer") {
      // Find all open squares
      var openSquares = [];
      board.forEach((square, i) => {
        if (!square) {
          openSquares.push(i);
        }
      });

      // Pick a random open square
      var i = Math.floor(Math.random() * openSquares.length);
      move(openSquares[i]);
    }

    // If hard computer, pick a square that will win or block a win
    else if (opponent === "Hard Computer") {
      // Find all open squares
      var openSquares = [];
      board.forEach((square, i) => {
        if (!square) {
          openSquares.push(i);
        }
      });

      // Check if any open square will win
      for (let i = 0; i < openSquares.length; i++) {
        var newBoard = [...board];
        newBoard[openSquares[i]] = turn;
        if (calculateWinner(newBoard) === turn) {
          move(openSquares[i]);
          return;
        }
      }

      // Check if any open square will block a win
      for (let i = 0; i < openSquares.length; i++) {
        var newBoard = [...board];
        newBoard[openSquares[i]] = turn === "X" ? "O" : "X";
        if (calculateWinner(newBoard) === (turn === "X" ? "O" : "X")) {
          move(openSquares[i]);
          return;
        }
      }

      // Otherwise, pick a random open square
      var i = Math.floor(Math.random() * openSquares.length);
      move(openSquares[i]);
    }
  }

  return (
    <pre>
      {/* Board */}
      {board.map((square, i) => {
        var output = <></>;

        // Filled
        if (square) {
          output = (
            <>
              {output}
              <span
                key={i}
                className={
                  (["/", "\\", "|", "───"].includes(square) && winner == "X") ||
                  square == "X"
                    ? "text-blue-500"
                    : "text-red-500"
                }
              >
                {square != "───" && " "}
                {square}
                {square != "───" && " "}
              </span>
            </>
          );
        }

        // Empty
        else {
          output = (
            <>
              {output}
              <span key={i} className="cursor-pointer" onClick={() => move(i)}>
                {"   "}
              </span>
            </>
          );
        }

        // Add separators
        if (i % 3 === 2 && i !== 8) {
          // Box characters
          // ┌┬─┬┐
          // ├┼─┼┤
          // └┴─┴┘
          output = (
            <>
              {output}
              <br />
              ───┼───┼───
              <br />
            </>
          );
        } else if (i !== 8) {
          output = (
            <>
              {output}
              {"|"}
            </>
          );
        }

        return <React.Fragment key={i}>{output}</React.Fragment>;
      })}
      <br />
      <br />

      {/* Winner */}
      {winner && (
        <div>
          {winner === "tie" ? (
            "Tie! "
          ) : (
            <p>
              <span
                className={winner == "X" ? "text-blue-500" : "text-red-500"}
              >
                {winner}
              </span>{" "}
              wins!{" "}
            </p>
          )}
          <span
            className="text-green-300 cursor-pointer hover:underline"
            onClick={reset}
          >
            [Play Again]
          </span>
        </div>
      )}
    </pre>
  );
}
