import React from "react";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Toggle({
  checked,
  onChange = () => {},
  disabled = false,
}) {
  return (
    <span
      onClick={() => {
        if (!disabled) {
          onChange(!checked);
        }
      }}
      className={classNames(
        "whitespace-nowrap",
        !disabled ? "cursor-pointer text-white" : "text-gray-500"
      )}
    >
      {checked ? "[x]" : "[ ]"}
    </span>
  );
}
