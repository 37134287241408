import React from "react";

export default function Error404() {
  return (
    <pre>
      <span className="text-red-500">Error 404: </span>
      This page does not exist.
    </pre>
  );
}
