import React from "react";
import { Link } from "react-router-dom";

export default function About() {
  return (
    <article>
      <h1 className="text-green-300"> --- About Me --- </h1>
      <br />
      <p>
        My name is{" "}
        <a
          target="_blank"
          className="text-blue-500"
          href="https://ethantchristensen.com"
        >
          [Ethan Christensen]
        </a>
        . I graduated from the{" "}
        <a href="https://www.utah.edu/" className="text-red-500">
          [U of U]
        </a>{" "}
        with a BS in Computer Science and a Minor in Mathematics in December 2022. I am currently working
        as a Software Engineer/Full Stack Web Dev. I'm just a guy who likes to code and play games.
        This is one of{" "}
        <a target="_blank" href="https://eef.app" className="text-blue-500">
          [my passion projects]
        </a>
        . I hope you enjoy it!
      </p>
      <br />
      <h1 className="text-green-300"> --- About [txt]ure --- </h1>
      <br />
      <p>
        <Link to="/" className="text-green-500">
          [txt]ure
        </Link>{" "}
        is a simple text based website that is (clearly) heavily inspired by the{" "}
        <a href="https://adventofcode.com/" className="text-blue-500">
          [Advent of Code]
        </a>
        . I first did the Advent of Code in 2023 and I absolutely loved it. I
        highly recomment anyone who likes code and/or puzzles to give it a try.
        The styling of the website was very simple and I wanted to recreate it
        and this is what I came up with. I hope you enjoy it!
      </p>
    </article>
  );
}
