import React from "react";
import { Link, Outlet } from "react-router-dom";

export default function Layout() {
  const pages1 = [
    {
      name: "Gallery",
      path: "/gallery",
    },
    {
      name: "Games",
      path: "/games",
    },
  ];

  const pages2 = [
    {
      name: "About",
      path: "/about",
    },
    {
      name: "Donate",
      path: "/donate",
    }
  ];

  return (
    <>
      <header className="mb-4 text-green-500 flex flex-wrap gap-x-6">
        <Link to="/">[txt]ure</Link>
        <div className="flex flex-wrap gap-x-6">
          <ul className="flex gap-2">
            {pages1.map((page) => {
              return (
                <li key={page.name}>
                  <Link to={page.path}>[{page.name}]</Link>
                </li>
              );
            })}
          </ul>
          <ul className="flex gap-2">
            {pages2.map((page) => {
              return (
                <li key={page.name}>
                  <Link to={page.path}>[{page.name}]</Link>
                </li>
              );
            })}
          </ul>
        </div>
      </header>
      <main>
        <Outlet />
      </main>
    </>
  );
}
