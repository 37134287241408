import React from "react";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Input({ type = "text", value, onChange, disabled, otherProps, className, style={} }) {
  return (
    <input
      type={type}
      value={value}
      onChange={onChange}
      disabled={disabled}
      style={style}
      className={classNames(
        "bg-gray-800 border-none p-0 pl-1 -my-2 py-1  h-[1.1em] align-middle",
        disabled ? "text-gray-500" : "text-white",
        className
      )}
      pattern={type === "number" ? "[0-9]*" : undefined}
      {...otherProps}
    />
  );
}
