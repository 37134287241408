import React from "react";
import TextArt from "../tools/textArt";

export default function Home() {
  return (
    <pre>
      {String.raw` ___ _        _  ___                
|  _| |      | ||_  |               
| | | |___  _| |_ | |_   _ _ __ ___ 
| | | __\ \/ / __|| | | | | '__/ _ \
| | | |_ >  <| |_ | | |_| | | |  __/
| |_ \__/_/\_\\__|| |\__,_|_|  \___|
|___|           |___|               `}
    </pre>
  );
}
