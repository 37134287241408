import React from "react";
import { useState, useEffect } from "react";
import moment from "moment";

export default function Timer({ startTime, running, className }) {
  // Timer that shows in HH:MM:SS.mm format how long it has been since startTime
  // startTime is expected to be a moment

  const [time, setTime] = useState(moment.duration(0));

  useEffect(() => {
    if (running) {
      const interval = setInterval(() => {
        setTime(moment.duration(moment().diff(startTime)));
      }, 10);
      return () => clearInterval(interval);
    }
  }, [startTime, running]);

  return (
    <span className={className}>
      {time.hours().toString().padStart(2, "0")}:
      {time.minutes().toString().padStart(2, "0")}:
      {time.seconds().toString().padStart(2, "0")}.
      {time.milliseconds().toString().padStart(2, "0")}
    </span>
  );
}
