import { Disclosure } from "@headlessui/react";
import moment from "moment";
import React, { useEffect } from "react";
import Timer from "../../components/Timer";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const CARD_VALUES = [
  "A",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "10",
  "J",
  "Q",
  "K",
];

// ♠♥♦♣  // Works with monospace font
// ♤♡♢♧ // Too wide. Don't work with monospace font
const SUITS = {
  // spade: "♤",
  // club: "♧",
  spade: "♠",
  club: "♣",
  heart: "♥",
  diamond: "♦",
  // heart: "♡",
  // diamond: "♢",
};

export default function Solitaire() {
  return (
    <div>
      <div className="mb-4">
        <Disclosure>
          <Disclosure.Button className="cursor-pointer hover:underline">
            [Toggle Rules]
          </Disclosure.Button>
          <Disclosure.Panel className="text-gray-500">
            <article>
              The goal of solitaire is to fill the "foundation" with all of the
              cards. Here are some rules:
              <br />
              <br />
              <b>Stock</b> - The stock is the pile of cards in the top left that
              you can draw from. If the stock is empty, it will be refilled with
              the cards from the waste
              <br />
              <br />
              <b>Waste</b> - The waste is the pile of cards next to the stock.
              This is where cards go from the stock when drawn.
              <br />
              <br />
              <b>Foundation</b> - The foundation is the four piles in the top
              right. This is where you want cards to end up. Piles in the
              foundation must be filled in ascending order, starting with an
              ace. This means that if there is an empty pile, only an ace may go
              there. If there is a non-empty pile, the next card to go into that
              pile must be the next card in the sequence and of the same suit.
              For example, if the top card is 3♠, the next card must be 4♠.
              <br />
              <br />
              <b>Tableau</b> - The tableau is the seven piles in the middle.
              This is where most of the action is. Cards can be moved around in
              the tableau based on the following rules:
              <ul>
                <li>- Piles must have alternating colors</li>
                <li>
                  - Piles must be in descending order (i.e. the card with no
                  other cards on top of it must be the lowest card value)
                </li>
                <li>- Only uncovered cards may be moved</li>
                <li>
                  - If a card is moved, all cards on top of it must also be
                  moved with it
                </li>
                <li>- Kings can only be moved to empty piles</li>
              </ul>
              When cards are moved such that an undiscovered card is now the top
              card, it is revealed.
              <br />
              <br />
              Cards can be moved between the tableau and the foundation as long
              as rules are followed. Only the top card of a pile can be moved
              the tableau and the foundation. The top card of the waste can also
              be moved into the foundation or tableau. However, no cards can be
              moved back to the waste.
              <br />
              <br />
              -----
              <br />
              <br />
              Winning is when all cards are in the foundation. Note that the
              tableau piles will be empty when this happens. Only ~80% of games
              are winnable so if you're stuck, don't feel bad about resetting.
              <br />
              <br />
              Good luck!
              <br />
              <br />
              -----
              <br />
              <br />
              Note: I'm not a solitaire expert so if I got any of the rules
              wrong or there are any bugs, please let me know! I appreciate any feedback.
            </article>
          </Disclosure.Panel>
        </Disclosure>
        <br />
        <Disclosure>
          <Disclosure.Button className="cursor-pointer hover:underline">
            [Toggle Controls]
          </Disclosure.Button>
          <Disclosure.Panel className="text-gray-500">
            <article>
              Click a card to move it. If it can't be moved, nothing will
              happen. Clicking the stock will draw a card to the waste. If the
              stock is empty, the waste will be used to refill the stock.
            </article>
          </Disclosure.Panel>
        </Disclosure>
      </div>
      <Game />
    </div>
  );
}

function Game() {
  const [state, setState] = React.useState(null);
  const [gameStatus, setGameStatus] = React.useState("playing"); // playing, won
  const [startTime, setStartTime] = React.useState(null);

  // Start game on first render
  useEffect(() => {
    if (!state) {
      reset();
    }
  }, [state]);

  if (!state) {
    return null;
  }

  function reset() {
    var deck = shuffle(makeDeck());

    var tableau = [];

    // Create tableau
    for (let i = 0; i < 7; i++) {
      tableau.push([]);
    }

    // Deal cards
    for (let i = 0; i < tableau.length; i++) {
      for (let j = 0; j <= i; j++) {
        tableau[i].push(deck.pop());
      }
    }

    // Reveal top card of each pile
    for (let i = 0; i < tableau.length; i++) {
      tableau[i][tableau[i].length - 1].hidden = false;
    }

    // Reveal everything in the stock
    for (let i = 0; i < deck.length; i++) {
      deck[i].hidden = false;
    }

    setState({
      stock: deck,
      waste: [],
      foundation: [[], [], [], []],
      tableau,
    });
    setGameStatus("playing");
    setStartTime(null);
  }

  /**
   * Returns a new deck of cards.
   * @returns A new deck of cards
   */
  function makeDeck() {
    const deck = [];
    for (const suit in SUITS) {
      for (const value of CARD_VALUES) {
        deck.push({
          suit,
          value,
          hidden: true,
        });
      }
    }
    return deck;
  }

  /**
   * Returns a new deck of cards, shuffled
   * using the Fisher-Yates Shuffle
   * algorithm.
   *
   * @param {*} deck Deck to shuffle
   */
  function shuffle(deck) {
    /**
     * Pseudocode from Wikipedia:
     * -- To shuffle an array a of n elements (indices 0..n-1):
     * for i from n−1 down to 1 do
     *   j ← random integer such that 0 ≤ j ≤ i
     *   exchange a[j] and a[i]
     *
     * Link: https://en.wikipedia.org/wiki/Fisher%E2%80%93Yates_shuffle
     */

    var newDeck = [...deck];

    for (let i = newDeck.length - 1; i > 0; i--) {
      let j = Math.floor(Math.random() * (i + 1)); // random integer such that 0 ≤ j ≤ i

      // swap
      let temp = newDeck[i];
      newDeck[i] = newDeck[j];
      newDeck[j] = temp;
    }

    return newDeck;
  }

  function handleFoundationClick(i) {
    // If empty, do nothing
    if (state.foundation[i].length === 0 || gameStatus !== "playing") {
      return;
    }
    if (!startTime) {
      setStartTime(moment());
    }

    var newState = copyState();

    var card = state.foundation[i][state.foundation[i].length - 1];

    // Check if can go to tableau
    var tableauPile = findPileFor(card);

    // If can go to tableau
    if (tableauPile !== null) {
      // Move card to tableau
      newState.tableau[tableauPile].push(card);

      // Remove card from foundation
      newState.foundation[i] = state.foundation[i].slice(0, -1);

      // Set state
      setState(newState);
      return;
    }
  }

  function handleStockClick() {
    if (gameStatus !== "playing") {
      return;
    }
    if (!startTime) {
      setStartTime(moment());
    }

    // If not empty, move top card to waste

    // If empty, move all cards from waste to stock

    if (state.stock.length > 0) {
      setState((state) => {
        return {
          ...state,
          stock: state.stock.slice(0, -1),
          waste: [...state.waste, state.stock[state.stock.length - 1]],
        };
      });
    } else {
      setState((state) => {
        return {
          ...state,
          stock: state.waste.reverse(),
          waste: [],
        };
      });
    }
  }

  function handleWasteClick() {
    // If empty, do nothing
    if (state.waste.length === 0 || gameStatus !== "playing") {
      return;
    }
    if (!startTime) {
      setStartTime(moment());
    }

    var newState = copyState();

    var card = state.waste[state.waste.length - 1];

    // Check if can go to foundation
    var foundationPile = findFoundationFor(card);

    // If can go to foundation
    if (foundationPile !== null) {
      // Move card to foundation
      newState.foundation[foundationPile].push(card);

      // Remove card from waste
      newState.waste = state.waste.slice(0, -1);

      // Set state
      setState(newState);
      return;
    }

    // Check if can go to tableau
    var tableauPile = findPileFor(card);

    // If can go to tableau
    if (tableauPile !== null) {
      // Move card to tableau
      newState.tableau[tableauPile].push(card);

      // Remove card from waste
      newState.waste = state.waste.slice(0, -1);

      // Set state
      setState(newState);
      return;
    }
  }

  function handleTableauClick(i, j) {
    // If empty or not playing, do nothing
    if (state.tableau[i].length === 0 || gameStatus !== "playing") {
      return;
    }

    if (!startTime) {
      setStartTime(moment());
    }

    var newState = copyState();

    var card = state.tableau[i][j];

    // Check if can go to foundation
    var foundationPile = findFoundationFor(card);

    // If can go to foundation (must be top card of pile)
    if (foundationPile !== null && j === state.tableau[i].length - 1) {
      // Move card and all cards on top of it to foundation
      var newFoundationPile = [...state.foundation[foundationPile]];
      newFoundationPile.push(...state.tableau[i].slice(j));
      newState.foundation[foundationPile] = newFoundationPile;

      // Remove cards from tableau
      newState.tableau[i] = state.tableau[i].slice(0, j);

      // Reveal new top card
      if (newState.tableau[i].length > 0) {
        newState.tableau[i][newState.tableau[i].length - 1].hidden = false;
      }

      // If all tableau piles are empty,
      // then the game is won
      if (newState.tableau.every((pile) => pile.length === 0)) {
        setGameStatus("won");
      }

      // Set state
      setState(newState);
      return;
    }

    // Check if can go to tableau
    var tableauPile = findPileFor(card);

    // If can go to tableau
    if (tableauPile !== null) {
      // Move card and all cards on top of it to tableau
      var newTableauPile = [...state.tableau[tableauPile]];
      newTableauPile.push(...state.tableau[i].slice(j));
      newState.tableau[tableauPile] = newTableauPile;

      // Remove cards from old tableau pile
      newState.tableau[i] = state.tableau[i].slice(0, j);

      // Reveal new top card
      if (newState.tableau[i].length > 0) {
        newState.tableau[i][newState.tableau[i].length - 1].hidden = false;
      }

      // Set state
      setState(newState);
      return;
    }
  }

  function copyState() {
    return {
      stock: [...state.stock],
      waste: [...state.waste],
      foundation: copyPiles(state.foundation),
      tableau: copyPiles(state.tableau),
    };
  }

  function copyPiles(piles) {
    // Deep copy the piles
    var newPiles = [];
    for (const pile of piles) {
      var newPile = [];
      for (const card of pile) {
        newPile.push({ ...card });
      }
      newPiles.push(newPile);
    }
    return newPiles;
  }

  function findPileFor(card) {
    // Find the pile that the card is in

    // For each pile in the tableau
    for (let i = 0; i < state.tableau.length; i++) {
      const pile = state.tableau[i];

      // If pile is empty,
      // skip if card is not a king
      // else return pile
      if (pile.length === 0) {
        if (card.value !== "K") {
          continue;
        } else {
          return i;
        }
      }

      // Get the top card of the pile
      const topCard = pile[pile.length - 1];

      // If top card and card are opposite colors and card is prevCard(topCard)
      if (
        topCard &&
        areOppColors(topCard, card) &&
        prevCard(topCard)?.value === card.value
      ) {
        return i;
      }
    }

    return null;
  }

  function findFoundationFor(card) {
    // If card is an ace, return the first empty foundation pile
    if (card.value === "A") {
      for (let i = 0; i < state.foundation.length; i++) {
        if (state.foundation[i].length === 0) {
          return i;
        }
      }
    }

    // If card is not an ace, return the foundation pile with the same suit
    // if card is nextCard(topCard)
    for (let i = 0; i < state.foundation.length; i++) {
      // If pile is empty, skip
      if (state.foundation[i].length === 0) {
        continue;
      }

      const topCard = state.foundation[i][state.foundation[i].length - 1];
      if (
        topCard &&
        topCard.suit === card.suit &&
        nextCard(topCard)?.value === card.value
      ) {
        return i;
      }
    }

    return null;
  }

  function nextCard(card) {
    // Return the next card in the sequence
    // e.g. nextCard({value: "A", suit: "spade"}) -> {value: "2", suit: "spade"}

    var valueIndex = CARD_VALUES.indexOf(card.value);
    var nextValue = CARD_VALUES[valueIndex + 1];

    if (nextValue) {
      return {
        value: nextValue,
        suit: card.suit,
      };
    } else {
      return null;
    }
  }

  function prevCard(card) {
    // Return the previous card in the sequence
    // e.g. prevCard({value: "2", suit: "spade"}) -> {value: "A", suit: "spade"}

    var valueIndex = CARD_VALUES.indexOf(card.value);
    var prevValue = CARD_VALUES[valueIndex - 1];

    if (prevValue) {
      return {
        value: prevValue,
        suit: card.suit,
      };
    } else {
      return null;
    }
  }

  function areOppColors(card1, card2) {
    // Return true if the cards are opposite colors
    // e.g. areOppColors({value: "A", suit: "spade"}, {value: "A", suit: "heart"}) -> true

    var isBlack1 = card1.suit === "spade" || card1.suit === "club";
    var isBlack2 = card2.suit === "spade" || card2.suit === "club";

    return isBlack1 !== isBlack2;
  }

  return (
    <div className="flex flex-col">
      {/* Stock, Waste, and Foundation  */}
      <div className="flex flex-row select-none">
        {/* Stock */}
        <Card
          hidden={state.stock.length > 0}
          onClick={handleStockClick}
          forceAllowClick
        />

        {/* Waste */}
        <Card
          {...state.waste[state.waste.length - 1]}
          onClick={handleWasteClick}
        />

        {/* Spacing */}
        <pre>{"    "}</pre>

        {/* Foundation */}
        {state.foundation.map((pile, i) => {
          return (
            <Card
              key={"foundation-" + i}
              {...pile[pile.length - 1]}
              onClick={() => {
                handleFoundationClick(i);
              }}
            />
          );
        })}
      </div>

      {/* Tableau */}
      <div className="flex flex-row gap-0 select-none">
        {
          // For each pile in the tableau
          state.tableau.map((pile, i) => {
            if (pile.length === 0) {
              return (
                <div key={"pile-" + i} className="flex flex-col gap-0">
                  <Card
                    suit={null}
                    value={null}
                    covered={false}
                    hidden={false}
                    onClick={() => {
                      handleTableauClick(i, 0);
                    }}
                  />
                </div>
              );
            }

            return (
              // Render the pile
              <div key={"pile-" + i} className="flex flex-col gap-0">
                {pile.map((card, j) => (
                  <Card
                    key={`${i}-${j}`}
                    {...card}
                    covered={j < pile.length - 1}
                    onClick={() => {
                      handleTableauClick(i, j);
                    }}
                  />
                ))}
              </div>
            );
          })
        }
      </div>

      {/* Timer */}
      {startTime && (
        <span>
          Time:{" "}
          <Timer
            startTime={startTime}
            running={gameStatus === "playing"}
            className={gameStatus === "playing" ? "text-gray-700" : ""}
          />
        </span>
      )}

      <br />

      {/* Status */}
      {gameStatus === "playing" ? (
        <div className="flex">
          <div
            className="text-green-300 hover:underline cursor-pointer"
            onClick={reset}
          >
            [Reset]
          </div>
        </div>
      ) : (
        <>
          <span className="text-green-500">You Won :)</span>{" "}
          <span
            className="text-green-300 cursor-pointer hover:underline"
            onClick={reset}
          >
            [Play Again]
          </span>
        </>
      )}
    </div>
  );
}

function Card({
  suit = null,
  value = null,
  hidden = false,
  covered = false,
  forceAllowClick = false,
  onClick = () => {},
}) {
  var text = `${value}${suit ? SUITS[suit] : " "}`;

  if (value === null || suit === null) {
    text = "  ";
  }

  if (hidden) {
    text = "░░";
  }

  return (
    <div
      className={classNames(
        "flex flex-col gap-0 shrink",
        hidden
          ? ""
          : suit === "heart" || suit === "diamond"
          ? "text-red-500"
          : "text-gray-500",
        covered ? "-mb-[0.5em]" : "",
        (!hidden || forceAllowClick) && "cursor-pointer hover:text-green-500"
      )}
      onClick={() => {
        if (!hidden || forceAllowClick) {
          onClick();
        }
      }}
    >
      <div>╭──╮</div>
      {(!covered || !hidden) && (
        <pre>
          {(value !== "10" || hidden) && "│"}
          {text}│
        </pre>
      )}
      {!covered && <div>╰──╯</div>}
    </div>
  );
}
