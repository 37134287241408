import React, { useEffect } from "react";
import RadioGroup from "../../components/RadioGroup";
import { Disclosure } from "@headlessui/react";
import Input from "../../components/Input";
import moment from "moment";
import Timer from "../../components/Timer";
import { motion } from "framer-motion";
import Toggle from "../../components/Toggle";
import { set } from "mongoose";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

// Phrases with lots of vowels, letters, and common letters
const EASY_PHRASES = [
  "Apple",
  "Banana",
  "Orange",
  "Strawberry",
  "Cute Dog",
  "Silly Cat",
  "Happy Birthday",
  "Happy New Year",
  "Happy Halloween",
  "Happy Holidays",
  "Sunny Day",
  "Hello World",
  "Toilet Paper",
  "Bathroom",
  "Bedroom",
  "Couch Potato",
  "Big House",
  "Cheeseburger",
  "Yellowstone",
  "Grand Canyon",
  "Mountain",
  "Pacific Ocean",
  "Atlantic Ocean",
  "Break a Leg",
  "Cup of Tea",
  "A Piece of Cake",
  "An Arm and a Leg",
  "A Chip on Your Shoulder",
  "A Dime a Dozen",
];

// Phrases with less letters, less vowels, and less common letters
const HARD_PHRASES = [
  "Spotlight",
  "Rhythm",
  "Zombie",
  "Zodiac",
  "Gossip",
  "Jazz",
  "Jukebox",
  "Frog",
  "Yacht",
  "Sphinx",
  "Queue",
  "Lazy Fox",
  "Hannukah",
  "Kwanzaa",
  "Acadia",
  "Zesty",

];

const MAX_LENGTH = 20;

export default function Hangman() {
  const [mode, setMode] = React.useState("Easy");
  const [customPhrase, setCustomPhrase] = React.useState("Hello World");
  const [showPhrase, setShowPhrase] = React.useState(false);

  return (
    <div>
      Phrase:
      <div className="flex gap-6">
        <RadioGroup
          options={["Easy", "Hard", "Custom"]}
          value={mode}
          onChange={setMode}
        />
        <div className="text-gray-500">
          <br />
          <br />
          <div className="flex flex-wrap gap-x-3">
            <div>
              Show Phrase:
              <Toggle
                checked={showPhrase}
                disabled={mode !== "Custom"}
                onChange={(b) => {
                  setShowPhrase(b);
                }}
              />
            </div>
            <div>
              Phrase:
              <Input
                className={showPhrase ? "" : "font-hidden"}
                disabled={mode !== "Custom"}
                style={{ width: MAX_LENGTH + 1 + "ch" }}
                type="text"
                value={customPhrase}
                otherProps={{
                  maxLength: MAX_LENGTH,
                }}
                onChange={(e) => {
                  // Ensure only letters
                  const regex = /^[A-Za-z ]*$/;
                  if (!e.target.value.match(regex)) {
                    return;
                  }

                  setCustomPhrase(e.target.value);
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <br />
      <div className="mb-4">
        <Disclosure>
          <Disclosure.Button className="cursor-pointer hover:underline">
            [Toggle Rules]
          </Disclosure.Button>
          <Disclosure.Panel className="text-gray-500">
            <article>
              The goal is to guess the phrase without making too many incorrect guesses.
              Once you guess all the letters in the phrase, you win!
              If you make seven incorrect guesses, you lose.
              All characters are only letters.
              </article>
          </Disclosure.Panel>
        </Disclosure>
        <br />
        <Disclosure>
          <Disclosure.Button className="cursor-pointer hover:underline">
            [Toggle Controls]
          </Disclosure.Button>
          <Disclosure.Panel className="text-gray-500">
            Click a letter to guess it.

            <br />
            <br />
            (Coming Soon: Type a letter to guess it.)
          </Disclosure.Panel>
        </Disclosure>
      </div>
      <Game
        mode={mode}
        customPhrase={mode === "Custom" ? customPhrase : null}
      />
    </div>
  );
}

function Game({ mode = "Easy", customPhrase = null }) {
  const [phrase, setPhrase] = React.useState(null);
  const [guess, setGuess] = React.useState(new Set());
  const [wrong, setWrong] = React.useState(0);
  const [gameStatus, setGameStatus] = React.useState("playing"); // playing, won, lost

  const NUM_WRONG = 7;

  useEffect(() => {
    reset();
  }, [customPhrase, mode]);

  useEffect(() => {
    if (!phrase) {
      reset();
    }
  }, [phrase]);

  function reset() {
    setGuess(new Set());
    setWrong(0);
    setGameStatus("playing");

    if (mode === "Custom") {
      setPhrase(customPhrase);
    } else {
      // Get random phrase
      const phrases = mode === "Easy" ? EASY_PHRASES : HARD_PHRASES;
      setPhrase(phrases[Math.floor(Math.random() * phrases.length)]);
    }
  }

  function handleGuess(letter) {
    // If not playing, ignore
    if (gameStatus !== "playing") return;

    // Lowercase
    letter = letter.toLowerCase();

    // Ignore if already guessed
    if (guess.has(letter)) return;

    // Add to guess
    setGuess((prev) => new Set(prev).add(letter));

    // Check if correct
    if (phrase.toLowerCase().includes(letter)) {
      var checkWith = new Set(guess);
      checkWith.add(letter);
      checkWith.delete(" ");

      // Check if won
      const won = [...new Set(phrase.toLowerCase())].every(
        (l) => checkWith.has(l) || l === " " || l === letter
      );
      if (won) {
        setGameStatus("won");
      }
    } else {
      // Check if lost
      if (wrong + 1 >= NUM_WRONG) {
        setGameStatus("lost");
      }
      setWrong((prev) => prev + 1);
    }
  }

  var textColor = "";
  if (gameStatus === "won") {
    textColor = "text-green-500";
  } else if (gameStatus === "lost") {
    textColor = "text-red-500";
  }

  return (
    <div className="flex flex-col md:flex-row items-center gap-12">
      {/* Hangman */}
      <div className="flex flex-col items-center gap-[2em]">
        <div>
          {/* Line 1 */}
          <pre>{"    +----+"}</pre>
          {/* Line 2 */}
          <pre>{"    |    |"}</pre>
          {/* Line 3 */}
          <pre>
            {"    |    "}
            <span className={textColor}>{wrong >= 1 ? "O" : " "}</span>
          </pre>
          {/* Line 4 */}
          <pre>
            {"    |   "}
            <span className={textColor}>
              {wrong >= 2 ? "/" : " "}
              {wrong >= 3 ? "|" : " "}
              {wrong >= 4 ? "\\" : " "}
            </span>
          </pre>
          {/* Line 5 */}
          <pre>
            {"    |    "}
            <span className={textColor}>{wrong >= 5 ? "|" : " "}</span>
          </pre>
          {/* Line 6 */}
          <pre>
            {"    |   "}
            <span className={textColor}>
              {wrong >= 6 ? "/ " : "  "}
              {wrong >= 7 ? "\\" : " "}
            </span>
          </pre>
          <pre>{"    |"}</pre>
          <pre>{"    |"}</pre>
          <pre>{"============="}</pre>
        </div>
        <Phrase guess={guess} phrase={phrase} status={gameStatus} />

        {/* Win Status */}
        <div>
          {gameStatus === "won" ? (
            <span className="text-green-500">You won!</span>
          ) : gameStatus === "lost" ? (
            <span className="text-red-500">You lost!</span>
          ) : null}{" "}
          {/* Reset */}
          {gameStatus !== "playing" && (
            <span
              onClick={reset}
              className="cursor-pointer hover:underline text-green-300"
            >
              [Play Again]
            </span>
          )}
        </div>
      </div>

      {/* Letters */}
      <div className="text-xl w-[10ch] cursor-default select-none">
        {"abcdefghijklmnopqrstuvwxyz".split("").map((letter, i) => (
          <React.Fragment key={letter}>
            <Letter
              guessed={guess.has(letter)}
              correct={new Set(phrase?.toLowerCase()).has(letter)}
              letter={letter}
              onGuess={handleGuess}
            />{" "}
          </React.Fragment>
        ))}
      </div>
    </div>
  );
}

function Letter({
  letter,
  guessed = false,
  correct = false,
  onGuess = () => {},
}) {
  return (
    <span
      className={classNames(
        guessed
          ? correct
            ? "text-green-500"
            : "text-gray-500"
          : "cursor-pointer hover:underline"
      )}
      onClick={() => {
        if (guessed) return;
        onGuess(letter);
      }}
    >
      {letter}
    </span>
  );
}

function Phrase({ guess, phrase, status }) {
  // Spaces are always revealed
  // Letters are revealed if they are guessed
  // Other characters are hidden (underscore)
  // Separate letters with spaces
  // Separate words with more spaces

  if (!phrase) return null;

  const letters = phrase.split("");
  const revealed = letters.map((letter) =>
    letter === " " ? " " : guess.has(letter.toLowerCase()) ? letter : "_"
  );
  return (
    <div
      className={classNames(
        status === "won"
          ? "text-green-500"
          : status === "lost"
          ? "text-red-500"
          : "",
        "text-xl text-center tracking-[1ch] break-before-page"
      )}
    >
      {revealed}
    </div>
  );
}
